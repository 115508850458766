import React, { createContext, useState } from 'react'

const initialState = {
  sidebarVisible: true,
  activeMenuKey: null,
  headerTabGroup: null,
    scientistProfileActivePanelKeys: [
        "ABOUT",
        "RESEARCH_INTEREST",
        "EXPERIENCE",
        "RESEARCH_EXPERIENCE",
        "RESEARCH_PROJECT",
        "EDUCATION",
        "TRAINING",
        "PUBLICATION",
        "AWARD_AND_GRANT"
    ],  // "all" panel is open by default
};

export const DataContext = createContext<any>(initialState);

export const DataContextProvider = ({ children }) => {
  const [sidebarVisible, changeSidebarVisible] = useState<boolean>(initialState.sidebarVisible);
  const [activeMenuKey, changeActiveMenuKey] = useState<any>(initialState.activeMenuKey);
  const [headerTabGroup, changeHeaderTabGroup] = useState<any>(initialState.headerTabGroup);
  const [scientistProfileActivePanelKeys, setScientistProfileActivePanelKeys] = useState<Array<string | number>>(initialState.scientistProfileActivePanelKeys);

  const setHeaderTabGroup = (tabGroup) => {
    changeHeaderTabGroup(tabGroup);
  };

  const setActiveMenuKey = (key) => {
    changeActiveMenuKey(key);
  };

  const setSidebarVisible = (status: any = null) => {
    if (status) {
      changeSidebarVisible(status);
    }
    else {
      changeSidebarVisible(!sidebarVisible);
    }
  };

  const contextValue = {
    sidebarVisible, activeMenuKey, headerTabGroup, scientistProfileActivePanelKeys,
    setHeaderTabGroup, setActiveMenuKey, setSidebarVisible, setScientistProfileActivePanelKeys
  };

  return (
    <DataContext.Provider value={contextValue}>
      {children}
    </DataContext.Provider>
  );
};

export default DataContextProvider;
