import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.RESEARCH_SERVER_PREFIX}/report`;
const endpoints = {
    getExampleReport: () => `${RESOURCE_ENDPOINT}/example-report`,
    getJournalApplicationReport: () =>
        `${RESOURCE_ENDPOINT}/journal-application-report`,
    getJournalArticleRequestedTitleReport: () =>
        `${RESOURCE_ENDPOINT}/journal-article-title-requested-report`,
    getPaymentInformatoionReport: () =>
        `${RESOURCE_ENDPOINT}/payment-information-report`,
    getPaymentInformationDetailsReport: () =>
        `${RESOURCE_ENDPOINT}/payment-information-details-report`,
    getFoundNotFoundReport: () =>
        `${RESOURCE_ENDPOINT}/article-found-not-found-report`,
    getFoundNotFoundDetailsReport: () =>
        `${RESOURCE_ENDPOINT}/article-found-not-found-details-report`,
    getResearchFieldWiseRequestReport: () =>
        `${RESOURCE_ENDPOINT}/research-field-wise-request-report`,
    getScientistInformationReport: () =>
        `${RESOURCE_ENDPOINT}/scientist-details-report`,
    getDisciplineSectorWiseReport: () =>
        `${RESOURCE_ENDPOINT}/discipline-sector-wise-scientist-report`,
    getScientistProfileReport: () =>
        `${RESOURCE_ENDPOINT}/scientist-profile-report`,
    getResearchDetailsReport: () =>
        `${RESOURCE_ENDPOINT}/research-details-report`,
    getPublicationDetailsReport: () =>
        `${RESOURCE_ENDPOINT}/publication-details-report`,
    getArchiveListByThesisId: () =>
        `${RESOURCE_ENDPOINT}/thesis-id-wise-archive-list`,
    getDynamicReport: () => `${RESOURCE_ENDPOINT}/dynamic-report/basic-info`,
    getScientistReport: () =>
        `${RESOURCE_ENDPOINT}/scientist-list-report`,
    getDynamicPublicationDetailsReport: () =>
        `${RESOURCE_ENDPOINT}/dynamic-report/publication-info`,
    getDynamicResearchProjectDetailsReport: () =>
        `${RESOURCE_ENDPOINT}/dynamic-report/research-project-info`,
    getDynamicScientistProfileBasicDetailsReport: () =>
        `${RESOURCE_ENDPOINT}/dynamic-report/scientist-basic-info`,
};

export default class ReportResearchApi {
    public getExampleReport = (
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getExampleReport();
        return HttpService.get(url, params, headers);
    };

    public getJournalApplicationReport = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getJournalApplicationReport();
        return HttpService.post(url, payload, params, headers);
    };

    public getJournalArticleRequestedTitleReport = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getJournalArticleRequestedTitleReport();
        return HttpService.post(url, payload, params, headers);
    };

    public getPaymentInformatoionReport = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getPaymentInformatoionReport();
        return HttpService.post(url, payload, params, headers);
    };

    public getPaymentInformationDetailsReport = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getPaymentInformationDetailsReport();
        return HttpService.post(url, payload, params, headers);
    };

    public getFoundNotFoundReport = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getFoundNotFoundReport();
        return HttpService.post(url, payload, params, headers);
    };

    public getFoundNotFoundDetailsReport = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getFoundNotFoundDetailsReport();
        return HttpService.post(url, payload, params, headers);
    };

    public getResearchFieldWiseRequestReport = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getResearchFieldWiseRequestReport();
        return HttpService.post(url, payload, params, headers);
    };

    public getScientistInformationReport = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getScientistInformationReport();
        return HttpService.post(url, payload, params, headers);
    };

    public getDisciplineSectorWiseReport = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getDisciplineSectorWiseReport();
        return HttpService.post(url, payload, params, headers);
    };

    public getScientistProfileReport = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getScientistProfileReport();
        return HttpService.post(url, payload, params, headers);
    };

    public getResearchDetailsReport = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getResearchDetailsReport();
        return HttpService.post(url, payload, params, headers);
    };

    public getPublicationDetailsReport = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getPublicationDetailsReport();
        return HttpService.post(url, payload, params, headers);
    };

    public getArchiveListByThesisId = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getArchiveListByThesisId();
        return HttpService.post(url, payload, params, headers);
    };
    
    public getDynamicReport = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getDynamicReport();
        return HttpService.post(url, payload, params, headers);
    };
    public getScientistReport = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getScientistReport();
        return HttpService.post(url, payload, params, headers);
    };

    public getDynamicScientistProfileBasicDetailsReport = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getDynamicScientistProfileBasicDetailsReport();
        return HttpService.post(url, payload, params, headers);
    };

    public getDynamicResearchProjectDetailsReport = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getDynamicResearchProjectDetailsReport();
        return HttpService.post(url, payload, params, headers);
    };

    public getDynamicPublicationDetailsReport = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.getDynamicPublicationDetailsReport();
        return HttpService.post(url, payload, params, headers);
    };
}
